/* ScriptureComponent.css */

.expanded-scripture {
    max-width: 700px;
    width: calc(100% - 20px);
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin: 16px 0;
    background-color: #ffffff;
}

/* Style for the verse number */
.expanded-scripture .v {
    font-size: 0.8em;
    color: #888;
    margin-right: 4px;
}

/* Style for the chapter headings */
.expanded-scripture .c {
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 20px;
}

/* Style for subheadings like "Opposition to the Rebuilding" */
.expanded-scripture .s1 {
    font-weight: bold;
    margin-top: 16px;
}

/* Footnote styling, if needed */
.expanded-scripture .f {
    font-size: 0.7em;
    color: #666;
}

.expanded-scripture .wj {
    color: red;
}

.expanded-scripture .iex {
    font-style: italic;
}
