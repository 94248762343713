* {
    box-sizing: border-box;
}

.dev-mode-panel {
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    max-height: 300px;
    max-width: 100%;
    overflow-x: auto;
    box-sizing: border-box;
}

.login-input-container {
    max-width: 400px;
}

.login-input {
    width: 100%;
    padding: 20px;
    margin: 10px;
    font-size: 1.2rem;
}

.login-button,
.disclaimer-button {
    width: 100%;
    padding: 15px;
    margin: 10px;
    font-size: 1.2rem;
    cursor: pointer;
}

.disclaimer-drawer {
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    transition: height 0.3s ease-in-out;
}

.disclaimer-drawer.open {
    height: auto;
    display: block;
}

.login-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.login-header div:first-child {
    flex-grow: 1;
}

.logout-button,
.toggle-dev-button {
    margin-left: 10px;
    font-size: small;
}

.content-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
}

button {
    min-width: 100px;
    padding: 5px 15px;
}

.error-message {
    color: red;
    margin-top: 1em;
}

.dev-mode-panel > textarea {
    width: 500px;
    height: 200px;
}

.button-and-settings-container {
    display: flex;
    align-items: center;
}

.login-header > div {
    display: inline-flex;
}

.disclaimer-drawer {
    padding-top: 20px;
    width: 500px;
    text-align: justify;
    display: none;
}
