.lesson-container {
    display: flex;
    flex-direction: column;
}

.left-nav {
    width: 100px;
}

.lesson-area {
    flex-grow: 1;
    padding-left: 10px;
    max-width: 800px;
    width: calc(100% - 10px);
    padding-right: 10px;
}

.lesson-area textarea {
    overflow-y: scroll;
    width: 100%;
    height: 80px;
}

.lesson-area textarea.passage-discovery-question {
    height: 400px;
}

.content-row {
    display: flex;
}

.lesson-area-v {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

#lesson-title h1 {
    margin-bottom: 0px;
}

#lesson-title h3 {
    margin-top: 0px;
}
