.left-nav {
    font-family: Arial, sans-serif;
    width: 250px;
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling the buttons as links */
.left-nav button.href-button {
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    padding: 5px 8px;
    border-radius: 3px;
    font-family: inherit; /* Ensure font consistency */
    text-decoration: none;
    color: #333;
    display: block;
    width: 100%; /* Makes the entire button clickable, not just the text */
    transition: background-color 0.3s, color 0.3s;
    outline: none; /* Removing the default outline to keep style consistent with the rest of the page */
}

.left-nav button.href-button:hover {
    background-color: #007BFF;
    color: #ffffff;
}

/* Main studies level */
.left-nav div {
    background-color: #E6F2FF;
    margin-bottom: 8px;
}

/* Expanded study */
.left-nav div > div {
    background-color: #B3D1FF;
    margin: 8px 0;
}

/* Expanded lesson within a study */
.left-nav div > div > div {
    background-color: #99C2FF;
    margin: 8px 0;
}

.left-nav div:hover,
.left-nav div > div:hover,
.left-nav div > div > div:hover {
    background-color: #80B2FF;
}

.left-nav button.href-button:hover {
    border-left: 3px solid #0056B3;
    padding-left: 5px;
}

.left-nav .selected-nav > button {
    background-color: #fcaf17;
}

.left-nav button.href-button:hover:before {
    color: #0056B3;
}

/* Default triangle pointing right */
.left-nav .expandable-nav > button.href-button:before {
    content: '\25BA';
    display: inline-block;
    margin-right: 10px;
    transition: transform 0.3s;
}

/* For expanded studies */
.left-nav .expandable-nav > button.href-button[aria-expanded='true']:before {
    transform: rotate(90deg); /* Point downwards when expanded */
}

.left-nav .lesson-nav,
.left-nav .day-nav {
    margin-left: 10px;
}
