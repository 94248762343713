.textarea-wrapper {
    display: grid;
}

.typeahead-input,
.typeahead-ghost {
    width: 100%;
    height: 100%;
    /* Ensure it covers the full height */
    border: 1px solid #ccc;
    padding: 8px;
    outline: none;
    overflow: hidden;
    box-sizing: border-box;
    resize: both;
    /* Ensures padding and border are included in width/height */
    grid-area: 1 / 1;
    /* This ensures both textareas are placed in the same grid cell */
}

.typeahead-input {
    background-color: transparent;
    position: relative;
    /* Ensures it's above the ghost textarea */
    z-index: 20;
}

.typeahead-ghost {
    color: #aaa;
    z-index: -1;
    /* This will place it behind the main textarea */
}